import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      customEventType: {
        DRMActivation: "DRMActivation",
        LoginCaptive: "LoginCaptive",
        OpenCollectionPage: "OpenCollectionPage",
        OpenContentPage: "OpenContentPage",
        OpenMicrositePage: "OpenMicrositePage",
        AddToFavorites: "AddToFavorites",
        PlayMovie: "PlayMovie",
        PlayAudio: "PlayAudio",
        PlayTrailer: "PlayTrailer",
        PlayAdvertise: "PlayAdvertise",
        Feedback: "Feedback",
        BuyItem: "BuyItem",
        StreamingContent: "StreamingContent",
        VideoPlayerError: "VideoPlayerError"
      },
      lastStreamingEvent: moment(0)
    };
  },
  computed: {
    ...mapState("user", {
      authUser: state => state.user
    })
  },
  methods: {
    ...mapActions("events", {
      _setEvent: "setEvent"
    }),
    async _drmActivationEvent(payload) {
      const event = {};
      event.eventType = this.customEventType.DRMActivation;
      event.userAgent = this.$browserDetect.meta.ua;
      event.activationResult = payload;
      await this._setEvent(event);
    },
    async _loginEvent() {
      const resolution = `${Math.max(
        window.screen.width,
        window.screen.height
      )}x${Math.min(window.screen.width, window.screen.height)}`;

      const event = {};
      event.eventType = this.customEventType.LoginCaptive;
      event.browser = this.$browserDetect.meta.name;
      event.screenResolution = resolution;
      event.drmActivation = this.$store.state.settings.enabledDrm
        ? "Success"
        : "Error";
      await this._setEvent(event);
    },
    async _collectionEvent(collectionId) {
      const event = {};
      event.eventType = this.customEventType.OpenCollectionPage;
      event.collectionId = collectionId;
      await this._setEvent(event);
    },
    async _contentEvent(contentId, cdbId, position) {
      const event = {};
      event.eventType = this.customEventType.OpenContentPage;
      event.contentId = contentId;
      event.cdbId = cdbId;
      event.position = position || "";
      await this._setEvent(event);
    },
    async _micrositeEvent(contentId, cdbId, position) {
      const event = {};
      event.eventType = this.customEventType.OpenMicrositePage;
      event.contentId = contentId;
      event.cdbId = cdbId;
      event.position = position || "";
      await this._setEvent(event);
    },
    async _playMovieEvent(contentId, cdbId, position) {
      const event = {};
      event.eventType = this.customEventType.PlayMovie;
      event.contentId = contentId;
      event.cdbId = cdbId;
      event.position = position || "";
      await this._setEvent(event);
    },
    async _playAudioEvent(contentId, cdbId, position) {
      const event = {};
      event.eventType = this.customEventType.PlayAudio;
      event.contentId = contentId;
      event.cdbId = cdbId;
      event.position = position || "";
      await this._setEvent(event);
      },
    async _playAdvertiseEvent(
      adVideoId,
      contentId,
      cdbId,
      position,
      rollPackId,
      skip
    ) {
      const event = {};
      event.eventType = this.customEventType.PlayAdvertise;
      event.contentId = contentId;
      event.cdbId = cdbId;
      event.position = position;
      event.rollPackId = rollPackId;
      event.adVideoId = adVideoId;
      event.isSkipped = skip || false;
      await this._setEvent(event);
    },
    async _playTrailerEvent(contentId, cdbId, position) {
      //todo На будущее
      const event = {};
      event.eventType = this.customEventType.PlayTrailer;
      event.contentId = contentId;
      event.cdbId = cdbId;
      event.position = position || "";
      await this._setEvent(event);
    },
    async _feedbackEvent(name, email, message, seat) {
      //todo Изменился
      const event = {};
      event.eventType = this.customEventType.Feedback;
      event.name = name;
      event.email = email;
      event.message = message;
      event.seat = seat;
      await this._setEvent(event);
    },
    async _buyItemEvent(content, payload) {
      const event = {
        eventType: this.customEventType.BuyItem,
        userName: payload.name,
        userEmail: payload.email,
        userPhone: payload.phone,
        userSeat: this.authUser.seat,
        itemId: content.id,
        cdbId: content.cdbId,
        itemName: content.title,
        itemType: content.paymentType,
        status: payload.status,
        price: content.price,
        result: "success"
      };
      await this._setEvent(event);
    },
    async _streamingEvent(contentId, cdbId, position, durationSec, sendPeriod) {
      const now = moment();
      // throttle (one event per 5 minutes for video, 1 min for audio)
      if (
        now
          .clone()
          .subtract(moment.duration(sendPeriod, "minutes"))
          .isBefore(this.lastStreamingEvent)
      ) {
        return;
      }
      this.lastStreamingEvent = now;
      const event = {};
      event.eventType = this.customEventType.StreamingContent;
      event.contentId = contentId;
      event.cdbId = cdbId;
      event.position = position || "";
      event.durationSec = Math.floor(durationSec);
      await this._setEvent(event);
    },
    async _videoPlayerErrorEvent(contentId, cdbId, position, error) {
      const event = {};
      event.eventType = this.customEventType.VideoPlayerError;
      event.contentId = contentId;
      event.cdbId = cdbId;
      event.position = position || "";
      event.durationSec = 0;
      event.error = error;
      await this._setEvent(event);
    }
  }
};
